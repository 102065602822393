import Vue from "vue";
import Vuex from "vuex";
import VuexI18n from "vuex-i18n";
import Api from "@/utils/api";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    infoUser: 0,
    roleName: "",
    notificationFlag: false,
    counterNotifications: false,
    user: null,
    start: true,
    userProfile: false,
    userProfileImg: false,
    isHome: false,
    apps: [],
    appActivity: [],
    formsAnswer: [],
    formsNoResponse: [],
    performingData: {},
    statusControl: null,
    documents: null,
    allUsers: [],
    listUserAll: [],
    references: null,
    breadcrums: [],
    personalReference: true,
  },
  mutations: {
    setInfoUser(state, value) {
      state.infoUser = value;
    },
    setRoleName(state, value) {
      state.roleName = value;
    },
    setNofitications(state) {
      state.notificationFlag = !state.notificationFlag;
    },
    setCountNotifications(state) {
      state.notificationFlag = !state.counterNotifications;
    },
    setUser(state, value) {
      state.user = value;
    },
    setStart(state) {
      state.start = !state.start;
    },
    setUserProfile(state) {
      state.userProfile = !state.userProfile;
    },
    setUserProfileImg(state) {
      state.userProfileImg = !state.userProfileImg;
    },
    setIsHome(state) {
      state.isHome = !state.isHome;
    },
    setApps(state, value) {
      state.apps = value;
    },
    setAppActivity(state, value) {
      state.appActivity = value;
    },
    setFormsAnswer(state, value) {
      state.formsAnswer = value;
    },
    setFormsNoResponse(state, value) {
      state.formsNoResponse = value;
    },
    setPerformingData(state, value) {
      state.performingData = value;
    },
    setStatusControl(state, value) {
      state.statusControl = value;
    },
    setDocuments(state, value) {
      state.documents = value;
    },
    setAllUsers(state, value) {
      state.allUsers = value;
    },
    setAllUsersNickName(state, value) {
      state.listUserAll = value;
    },
    setReferences(state, value) {
      state.references = value;
    },
    setBreadcrums(state, value) {
      state.breadcrums = value;
    },
    setPersonalReference(state) {
      state.personalReference = !state.personalReference;
    },
  },
  actions: {
    updateNofi(context) {
      context.commit("setNofitications");
    },
    updateCountNotifications(context) {
      context.commit("setCountNotifications");
    },
    updateUserInfo(context, value) {
      context.commit("setUser", value);
    },
    updateStart(context) {
      context.commit("setStart");
    },
    updateUserProfile(context) {
      context.commit("setUserProfile");
    },
    updateUserProfileImg(context) {
      context.commit("setUserProfileImg");
    },
    updateIsHome(context) {
      context.commit("setIsHome");
    },
    updateApps(context, value) {
      context.commit("setApps", value);
    },
    updateAppActivity(context, value) {
      context.commit("setAppActivity", value);
    },
    updateFormsAnswer(context, value) {
      context.commit("setFormsAnswer", value);
    },
    updateFormsNoResponse(context, value) {
      context.commit("setFormsNoResponse", value);
    },
    updatePerformingData(context, value) {
      context.commit("setPerformingData", value);
    },
    updateStatusControl(context, value) {
      context.commit("setStatusControl", value);
    },
    async fetchAndUpdateDocuments(context) {
      try {
        //console.log('[Store] Iniciando fetchAndUpdateDocuments');
        const tokenB64 = localStorage.getItem("tokenB64");
        if (!tokenB64) {
          console.error('[Store] No se encontró el token de autenticación');
          return null;
        }
        
        //console.log('[Store] Obteniendo documentos del servidor');
        const config = {
          headers: {
            Authorization: "Bearer " + tokenB64
          }
        };
        
        // Primero obtenemos los documentos completados
        //console.log('[Store] Obteniendo documentos completados...');
        const completedResponse = await Api.Auth().signCompleted(config);
        
        // Luego obtenemos los documentos pendientes
        //console.log('[Store] Obteniendo documentos pendientes...');
        const pendingResponse = await Api.Auth().signPending(config);
        
        //console.log('[Store] Respuestas del servidor:', {
        //  completed: completedResponse?.data,
        //  pending: pendingResponse?.data
        //});

        if (completedResponse?.data?.cod === 0 || pendingResponse?.data?.cod === 0) {
          const documents = {
            completed: completedResponse?.data?.data || [],
            pending: pendingResponse?.data?.data || []
          };
          //console.log('[Store] Documentos procesados:', documents);
          context.commit("setDocuments", documents);
          return documents;
        } else {
          //console.error('[Store] Las respuestas no tienen el formato esperado:', {
          //  completed: completedResponse,
          //  pending: pendingResponse
          //});
          return null;
        }
      } catch (error) {
        //console.error('[Store] Error en fetchAndUpdateDocuments:', error);
        if (error.response) {
          console.error('[Store] Detalles del error:', {
            status: error.response.status,
            data: error.response.data,
            headers: error.response.headers
          });
        }
        throw error;
      }
    },
    updateDocuments(context, value) {
      //console.log('[Store] Actualizando documentos en store:', value);
      context.commit("setDocuments", value);
    },
    updateAllUsers(context, value) {
      context.commit("setAllUsers", value);
    },
    updateAllUsersNickName(context, value) {
      context.commit("setAllUsersNickName", value);
    },
    updateReferences(context, value) {
      context.commit("setReferences", value);
    },
    updateBreadcrums(context, value) {
      context.commit("setBreadcrums", value);
    },
    updatePersonalReference(context, value) {
      context.commit("setPersonalReference", value);
    },
  },
  modules: {},
  getters: {
    getInfoUser(state) {
      return state.infoUser;
    },
    getRoleName(state) {
      return state.roleName;
    },
    getNofitications(state) {
      return state.notificationFlag;
    },
    getCountNotifications(state) {
      return state.counterNotifications;
    },
    getUser(state) {
      return state.user;
    },
    getStart(state) {
      return state.start;
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    getUserProfileImg(state) {
      return state.userProfileImg;
    },
    getIsHome(state) {
      return state.isHome;
    },
    getApps(state) {
      return state.apps;
    },
    getAppActivity(state) {
      return state.appActivity;
    },
    getFormsAnswer(state) {
      return state.formsAnswer;
    },
    getFormsNoResponse(state) {
      return state.formsNoResponse;
    },
    getPerformingData(state) {
      return state.performingData;
    },
    getStatusControl(state) {
      return state.statusControl;
    },
    getDocuments(state) {
      return state.documents;
    },
    getAllUsers(state) {
      return state.allUsers;
    },
    getAllUsersNickName(state) {
      return state.listUserAll;
    },
    getReferences(state) {
      return state.references;
    },
    getBreadcrums(state) {
      return state.breadcrums;
    },
    getPersonalReference(state) {
      return state.personalReference;
    },
  },
});

Vue.use(VuexI18n.plugin, store);

export default store;
